<template>
  <div>
    <div class="title">{{ $t("productDetails") }}</div>
    <div class="company">{{ form.name }}</div>
    <div class="flex-row">
      <div class="lantian">
        <img :src="form.user && form.user.spDetail && form.user.spDetail.companyLogo" alt />
      </div>
      <div class="flex-wrap">
        <div class="flex-row top-view">
          <div class="flex-column-center" style="min-width: 310px;">
            <div>{{ $t("raisingTotalAmount") }}</div>
            <div class="text-org">{{ form.financingAmount | toThousandFilterTwo }}</div>
          </div>
          <div class="flex-column-center" style="border: none">
            <div>{{ $t("annualizedIncome") }}</div>
            <div class="text-org">{{ form.interestRate ? form.interestRate + '%' : '0%' }}</div>
          </div>
          <div class="flex-column-center" style="border: none">
            <div>{{ $t("termOfInvestment") }}</div>
            <div class="text-org">{{ form.borrowingCycle }} {{ $t("day") }}</div>
          </div>
        </div>
        <div style="line-height: 30px">
          {{ $t("deadlineOffering") }}{{ form.deadlineFundRaising }}
          <!-- <span
            class="text-red"
          >{{ form.intervalDay }} {{ $t("daySurplus") }}</span>-->
          <span style="margin-left: 250px">{{ $t("expectedDate") }}{{ form.shipmentDate }}</span>
          <div>{{ $t("repaymentDateAgain") }}{{ form.promisedPaymentDate }}</div>
        </div>
      </div>
    </div>
    <div class="title">{{ $t("financingInformation") }}</div>
    <div class="flex-row top-view">
      <div class="flex-column-center">
        <div>{{ $t("aleayAmountRaised") }}</div>
        <div class="text-org">{{ form.investAmount | toThousandFilterTwo }}</div>
      </div>
      <div class="flex-column-center">
        <div>{{ $t("investableAmount") }}</div>
        <div class="text-org">{{ form.investableAmount | toThousandFilterTwo }}</div>
      </div>
      <div class="flex-column-center">
        <div>{{ $t("investmentProgress") }}</div>
        <div class="text-org">{{ form.progress }}</div>
      </div>
    </div>
    <div v-show="false" class="title">{{ $t("chainInformation") }}</div>
    <div v-show="false" class="flex-row top-view border-bottom-none">
      <div class="flex-column-center">
        <div>{{ $t("purchaser") }}</div>
        <div class="text-org">{{ form.name }}</div>
      </div>
      <div class="flex-column-center">
        <div>{{ $t("cooperationPeriod") }}</div>
        <div class="text-org">5 {{ $t("years") }}</div>
      </div>
      <div class="flex-column-center">
        <div>{{ $t("cooperationOrder") }}</div>
        <div class="text-org">20 {{ $t("millionDollars") }}/{{ $t("year") }}</div>
      </div>
    </div>
    <div class="center">
      <el-button class="btn-black" @click="backPage">
        {{
        $t("btnI18n.Return")
        }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { differenceInDays } from 'date-fns';
import { BigNumber } from 'bignumber.js';
export default {
  data() {
    return {
      account: undefined,
      fundRequestId: null,
      investAmount: null,
      form: {},
      extraData: {},
      logoUrl: ""
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      let _this = this;
      if (!this.$route.query.id) return false;
      this.$axios.get("/v1/invest/financing-order-detail", { params: { id: this.$route.query.id } }).then(result => {
        if (result) {
          _this.form = result.data;
          _this.form.name = _this.form.user && _this.form.user.username;
          _this.form.logoUrl = _this.form.user && _this.form.userspDetail && _this.form.userspDetail.companyLogo;
          _this.form.financingAmount = _this.form.financingAmount ? Number(_this.form.financingAmount) / this.$enums.UNIT_MILLION : '0.00';
          _this.form.investAmount = _this.form.investAmount ? Number(_this.form.investAmount) / this.$enums.UNIT_MILLION : '0.00';
          // 可投资金额
          const financingAmount = Number(_this.form.financingAmount);
          const investAmount = Number(_this.form.investAmount);
          const financingAmountSure = new BigNumber(financingAmount);
          const investAmountSure = new BigNumber(investAmount);
          _this.form.investableAmount = financingAmountSure.minus(investAmountSure);
          // _this.form.investableAmount = Number(_this.form.financingAmount) - Number(_this.form.investAmount);

          _this.form.progress = ((Number(_this.form.investAmount) / Number(_this.form.financingAmount)) * 100) ? ((Number(_this.form.investAmount) / Number(_this.form.financingAmount)) * 100).toFixed(2) + '%' : '0.00%';
          _this.form.intervalDay = differenceInDays(new Date(_this.form.promisedPaymentDate), new Date());
        }
      });
    },
    backPage() {
      this.$router.go(-1);
    }
  }
  // async beforeCreate() {
  //   await connect();
  //   let accounts = await web3.eth.getAccounts();
  //   this.address = accounts[0];
  // },
};
</script>

<style lang="scss" scoped>
.company {
  height: 40px;
  background: #81d8d0;
  border-radius: 5px;
  font-size: 18px;
  line-height: 40px;
  color: white;
  padding-left: 20px;
}
.lantian {
  width: 216px;
  height: 96px;
  border-radius: 5px;
  border: 1px solid #979797;
  margin-top: 30px;
  padding: 24px 10px;
  margin-right: 40px;
  margin-bottom: 40px;
  text-align: center;
  img {
    width: 180px;
    height: 90px;
  }
}
.top-view {
  height: 120px;
  //border-bottom: 1px solid #979797;
  margin-bottom: 20px;
  line-height: 30px;
  > div {
    flex: 1;
    border-left: 1px solid #979797;
  }
  > div:nth-child(1) {
    border: none;
  }
  .text-org {
    font-size: 20px;
  }
  // > div:nth-child(2) {
  //   border-left: 1px solid #979797;
  //   border-right: 1px solid #979797;
  // }
}
.text-red {
  font-size: 14px;
  color: #81d8d0;
  margin-left: 10px;
}
.account {
  width: 200px;
  margin: 20px;
}
.border-bottom-none {
  border-bottom: none;
}
.center {
  text-align: center;
}
</style>
